import Lenis from "@studio-freight/lenis"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

let lenis

export function initializeLenis() {
  // Create Lenis instance with optimized settings
  lenis = new Lenis({
    duration: 1.2,
    easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
    orientation: "vertical",
    smoothWheel: true,
    wheelMultiplier: 1,
    smoothTouch: false, // Disable smooth touch by default
    touchMultiplier: 2,
  })

  // Sync ScrollTrigger with Lenis
  lenis.on("scroll", ScrollTrigger.update)

  // Create a more efficient RAF loop
  function raf(time) {
    lenis.raf(time)
    requestAnimationFrame(raf)
  }

  // Start the RAF loop
  requestAnimationFrame(raf)

  // Update ScrollTrigger on Lenis scroll
  ScrollTrigger.addEventListener("refresh", () => lenis.resize())

  // Optional: Pause Lenis when ScrollTrigger scrubs
  ScrollTrigger.defaults({
    onUpdate: (self) => {
      if (self.trigger && self.trigger.contains(document.querySelector("[data-lenis-prevent]"))) {
        return
      }
      if (self.isActive && self.scrub) {
        lenis.stop()
      } else {
        lenis.start()
      }
    },
  })
}

export function getLenis() {
  return lenis
}
