import collapse from '@alpinejs/collapse'
import intersect from '@alpinejs/intersect'
import persist from '@alpinejs/persist'
import Alpine from 'alpinejs'

import animations from '../animations/animations'
import accordion from '../components/accordion'
import modal from '../components/modal'
import sidebarScroll from './sidebar-scroll'

export function initializeAlpinePlugins() {
	if (window.Alpine) {
		return // Alpine is already initialized
	}

	Alpine.plugin(intersect)
	Alpine.plugin(collapse)
	Alpine.plugin(persist)

	window.Alpine = Alpine

	Alpine.data('animations', animations)
	Alpine.data('sidebarScroll', sidebarScroll)
	Alpine.data('accordion', accordion)
	Alpine.data('modal', modal)

	Alpine.start()
}

export function reinitializeAlpine() {
	// Store all Alpine elements
	const alpineElements = document.querySelectorAll('[x-data]')

	// Clean up existing elements
	alpineElements.forEach((el) => {
		if (el._x_dataStack) {
			delete el._x_dataStack
		}
		if (el.__x) {
			delete el.__x
		}
	})

	// Only initialize Alpine if it's not already initialized
	if (!window.Alpine) {
		initializeAlpinePlugins()
	} else {
		// If Alpine exists, just refresh the components
		Alpine.start()
	}
}
