import { initializeAlpinePlugins } from "./utils/alpine-init"
import { initializeFancybox } from "./utils/fancybox-init"
import $fetch from "./utils/fetch"
import { initializeGSAPPlugins } from "./utils/gsap-init"
import { initializeHeadroom } from "./utils/headroom-init"

const env = import.meta.env || {}

window.$fetch = $fetch

function initializeApp() {
  initializeAlpinePlugins()
  initializeHeadroom()
}

document.addEventListener("DOMContentLoaded", () => {
  initializeGSAPPlugins()
  initializeFancybox()
})

initializeApp()
