import Headroom from 'headroom.js'

const HEADROOM_OPTIONS = {
	offset: 0,
	tolerance: 0,
	classes: {
		initial: 'headroom',
		pinned: 'headroom--pinned',
		unpinned: 'headroom--unpinned',
	},
	onPin: () => {
		document.body.classList.add('pinned')
	},
	onUnpin: () => {
		document.body.classList.remove('pinned')
	},
}

export function initializeHeadroom() {
	const header = document.querySelector('.header')
	const preloader = document.getElementById('preloader')

	if (preloader) {
		preloader.style.display = 'none'
	}
	if (header) {
		header.classList.remove('hidden')
		const headroom = new Headroom(header, HEADROOM_OPTIONS)
		headroom.init()
	}
}
