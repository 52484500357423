import gsap from "gsap"
import DrawSVGPlugin from "gsap/DrawSVGPlugin"
import { Flip } from "gsap/Flip"
import ScrollTrigger from "gsap/ScrollTrigger"

import { createHorizontalScroll, gsapAnimations } from "../animations/gsap-animations"
// import { initTimelineAnimations } from "../components/timeline"
import { initializeLenis } from "./lenis-init"
import { initializePreloader } from "./preloader"

const GSAP_UNDEFINED_MESSAGE = "GSAP or its plugins are not loaded. Please check your imports and GSAP license."

// Register plugins immediately
if (gsap.registerPlugin) {
  gsap.registerPlugin(DrawSVGPlugin, ScrollTrigger, Flip)
}

// Create a promise to track GSAP initialization
window.gsapReadyPromise = new Promise((resolve, reject) => {
  try {
    window.isGsapReady = () => true
    window.animateMegaMenu = (element) => {
      gsap.set(element, { opacity: 1 })
      return gsap.from(element.querySelectorAll("[data-menu-item]"), {
        opacity: 0,
        y: 20,
        duration: 0.5,
        stagger: 0.1,
        ease: "power2.out",
      })
    }

    if (typeof gsap === "undefined") {
      throw new Error(GSAP_UNDEFINED_MESSAGE)
    }

    // Make plugins globally available
    window.gsap = gsap
    window.ScrollTrigger = ScrollTrigger
    window.DrawSVGPlugin = DrawSVGPlugin
    window.Flip = Flip

    // Log plugin availability
    // console.log('GSAP Plugins Status:', {
    // 	gsap: !!window.gsap,
    // 	ScrollTrigger: !!window.ScrollTrigger,
    // 	DrawSVGPlugin: !!window.DrawSVGPlugin,
    // 	Flip: !!window.Flip,
    // 	drawSVG: typeof DrawSVGPlugin !== 'undefined',
    // })

    resolve(true)
  } catch (error) {
    console.error("GSAP initialization failed:", error)
    reject(error)
  }
})

function cleanupAnimations() {
  ScrollTrigger.getAll().forEach((st) => st.kill())
}

// function setInitialAnimationStates() {
//   wrapElementsWithMask(".slide-up")
//   gsap.set(".slide-up", { yPercent: 110 })
//   gsap.set(".fade-up", { autoAlpha: 0, yPercent: 110 })
// }

// function setupBackgroundColorTransitions() {
// 	const sections = document.querySelectorAll('[data-bg]')

// 	sections.forEach((section) => {
// 		const bgColor = section.getAttribute('data-bg')
// 		const currentBgColor = window.getComputedStyle(section).backgroundColor

// 		gsap.fromTo(
// 			section,
// 			{ backgroundColor: '#fbf9f5' },
// 			{
// 				backgroundColor: bgColor,
// 				immediateRender: false,
// 				scrollTrigger: {
// 					trigger: section,
// 					start: 'top 90%',
// 					end: 'bottom 10%',
// 					scrub: false,
// 					onEnter: () => gsap.to(section, { backgroundColor: bgColor, duration: 0.5 }),
// 					onLeave: () =>
// 						gsap.to(section, {
// 							backgroundColor: currentBgColor,
// 							duration: 0.5,
// 						}),
// 					onEnterBack: () => gsap.to(section, { backgroundColor: bgColor, duration: 0.5 }),
// 					onLeaveBack: () =>
// 						gsap.to(section, {
// 							backgroundColor: currentBgColor,
// 							duration: 0.5,
// 						}),
// 				},
// 			},
// 		)
// 	})
// }

function setupAnimations() {
  cleanupAnimations()
  // setInitialAnimationStates()

  // Add a small delay before initializing animations
  setTimeout(() => {
    try {
      // Debug logging to check selectors
      const selectors = [
        // ".fade-up",
        // ".slide-up",
        // ".fade-down",
        // ".slide-down",
        ".rollover-button",
        ".curtain-hover:not(.project-card .curtain-hover)",
        ".project-card .curtain-hover",
        ".horizontal-scroll-container",
        ".scale-image",
      ]

      // selectors.forEach((selector) => {
      // 	const elements = document.querySelectorAll(selector)
      // 	console.log(`Selector "${selector}": found ${elements.length} elements`)
      // })

      // gsapAnimations.fadeUp(".fade-up", { duration: 1, stagger: 0.1 })
      // gsapAnimations.slideUp(".slide-up", { duration: 1, stagger: 0.1 })
      // gsapAnimations.fadeDown(".fade-down", {
      //   duration: 1,
      //   stagger: 0.1,
      //   splitText: { type: "lines" },
      // })
      // gsapAnimations.slideDown(".slide-down", {
      //   duration: 1,
      //   stagger: 0.1,
      //   splitText: { type: "lines" },
      // })
      gsapAnimations.buttonRollover(".rollover-button")
      gsapAnimations.curtainHover(".curtain-hover:not(.project-card .curtain-hover)")
      gsapAnimations.projectCardCurtainHover(".project-card .curtain-hover")

      // Handle multiple horizontal scroll containers
      gsap.utils.toArray(".horizontal-scroll-container").forEach((container) => {
        if (container) {
          createHorizontalScroll(container)
        }
      })

      gsap.utils.toArray(".scale-image").forEach((image) => {
        if (image) {
          gsap.fromTo(
            image,
            { scale: 1.2 },
            {
              scale: 1,
              scrollTrigger: {
                trigger: image.parentElement,
                start: "top bottom",
                end: "bottom top",
                scrub: 0.1,
                ease: "none",
                immediateRender: false,
                invalidateOnRefresh: true,
              },
            },
          )
        }
      })

      gsap.utils.toArray(".scroll-image").forEach((image) => {
        if (image) {
          gsap.fromTo(
            image,
            { yPercent: 10 },
            {
              yPercent: -10,
              scrollTrigger: {
                trigger: image.parentElement,
                start: "top bottom",
                end: "bottom top",
                scrub: 0.1,
                ease: "none",
                immediateRender: false,
                invalidateOnRefresh: true,
              },
            },
          )
        }
      })

      // setupBackgroundColorTransitions()
    } catch (error) {
      console.error("Error setting up animations:", error)
    }
  }, 100) // 100ms delay, adjust as needed
}

function setupWindowLoadHandler() {
  window.addEventListener("load", () => {
    if (!sessionStorage.getItem("preloader")) {
      initializePreloader(() => window.scrollTo(0, 0))
    }
  })
}

export function initializeGSAPPlugins() {
  window.gsapReadyPromise.then(() => {
    const initializeAll = () => {
      // setInitialAnimationStates()
      setupAnimations()
      initializeLenis()
      // initTimelineAnimations()

      // Move refresh inside a RAF to ensure DOM is ready
      // requestAnimationFrame(() => {
      //   ScrollTrigger.refresh()
      // })
    }

    // Wait for DOM Content to be fully loaded
    if (document.readyState === "loading") {
      document.addEventListener("DOMContentLoaded", initializeAll)
    } else {
      initializeAll()
    }

    setupWindowLoadHandler()
    gsap.ticker.lagSmoothing(0)
  })
}
