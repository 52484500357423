import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

export const gsapAnimations = {
  wrapElementsWithMask(elements) {
    if (!elements) {
      console.warn("wrapElementsWithMask: No elements provided")
      return
    }
    const elementsArray = gsap.utils.toArray(elements)
    if (elementsArray.length === 0) {
      console.warn(`wrapElementsWithMask: No elements found for selector "${elements}"`)
      return
    }
    elementsArray.forEach((element) => {
      if (!element.parentNode.classList.contains("mask")) {
        const mask = document.createElement("div")
        mask.classList.add("mask")
        element.parentNode.insertBefore(mask, element)
        mask.appendChild(element)
      }
    })
  },

  fadeUp(elements, options = {}) {
    const targets = gsap.utils.toArray(elements)
    if (targets.length === 0) {
      console.warn(`fadeUp: No elements found for selector "${elements}"`)
      return
    }
    targets.forEach((target) => {
      gsap.fromTo(
        target,
        { autoAlpha: 0, y: 50 },
        {
          autoAlpha: 1,
          y: 0,
          duration: options.duration || 1,
          scrollTrigger: {
            trigger: target,
            start: "top bottom-=100",
          },
        },
      )
    })
  },

  slideUp(elements, options = {}) {
    const targets = gsap.utils.toArray(elements)
    if (targets.length === 0) {
      console.warn(`slideUp: No elements found for selector "${elements}"`)
      return
    }
    targets.forEach((target) => {
      gsap.fromTo(
        target,
        { y: 50 },
        {
          y: 0,
          duration: options.duration || 1,
          scrollTrigger: {
            trigger: target,
            start: "top bottom-=100",
          },
        },
      )
    })
  },

  fadeDown(elements, options = {}) {
    const targets = gsap.utils.toArray(elements)
    if (targets.length === 0) {
      console.warn(`fadeDown: No elements found for selector "${elements}"`)
      return
    }
    targets.forEach((target) => {
      gsap.fromTo(
        target,
        { autoAlpha: 0, y: -50 },
        {
          autoAlpha: 1,
          y: 0,
          duration: options.duration || 1,
          scrollTrigger: {
            trigger: target,
            start: "top bottom-=100",
          },
        },
      )
    })
  },

  slideDown(elements, options = {}) {
    const targets = gsap.utils.toArray(elements)
    if (targets.length === 0) {
      console.warn(`slideDown: No elements found for selector "${elements}"`)
      return
    }
    targets.forEach((target) => {
      gsap.fromTo(
        target,
        { y: -50 },
        {
          y: 0,
          duration: options.duration || 1,
          scrollTrigger: {
            trigger: target,
            start: "top bottom-=100",
          },
        },
      )
    })
  },

  createHorizontalScroll(container, options = {}) {
    const mm = gsap.matchMedia()

    // Mobile-specific logic
    mm.add("(max-width: 768px)", () => {
      ScrollTrigger.getAll().forEach((st) => {
        if (st.vars.trigger === container || st.vars.trigger.contains(container)) {
          st.kill()
        }
      })
      container.classList.remove("h-svh")
      container.classList.add("py-12")
      return
    })

    // Default/desktop-specific logic
    mm.add("(min-width: 769px)", () => {
      container.classList.add("h-svh")

      const scrollItems = gsap.utils.toArray(container.querySelectorAll(".horizontal-scroll-item"))

      if (scrollItems.length === 0) {
        console.warn(`createHorizontalScroll: No elements found for selector "${container}"`)
        return
      }

      const totalWidth = scrollItems.reduce((acc, el) => acc + el.offsetWidth * 1.125, 0)

      // Create the horizontal scroll animation
      const horizontalTween = gsap.to(scrollItems, {
        x: () => -(totalWidth - container.offsetWidth),
        ease: "none",
        scrollTrigger: {
          trigger: container,
          start: "top top",
          end: () => `+=${totalWidth}`,
          pin: true,
          anticipatePin: 0,
          scrub: 1,
          invalidateOnRefresh: true,
          pinSpacing: true,
          pinType: "margin",
          touchMobile: true,
          onUpdate: (self) => {
            if (self.progress === 1) {
              document.body.style.overscrollBehavior = "auto"
            } else {
              document.body.style.overscrollBehavior = "none"
            }
          },
        },
      })

      // Crucial iOS fixes
      window.addEventListener(
        "touchmove",
        (e) => {
          if (ScrollTrigger.isInViewport(container)) {
            e.preventDefault()
          }
        },
        { passive: false },
      )

      // Disable body scroll when in horizontal scroll mode
      container.addEventListener("touchstart", () => {
        document.body.style.overflow = "hidden"
      })

      // Re-enable body scroll when horizontal scroll mode ends
      container.addEventListener("touchend", () => {
        document.body.style.overflow = "auto"
      })
    })
  },

  buttonRollover(buttons) {
    gsap.utils.toArray(buttons).forEach((button) => {
      const buttonText = button.querySelector(".button-text")
      if (!buttonText) {
        console.warn('Button rollover effect requires a span with class "button-text"')
        return
      }

      if (buttonText.getAttribute("data-text") === null) {
        buttonText.setAttribute("data-text", buttonText.textContent)
      }

      const buttonTextAfter = button.querySelector(".button-text::after")
      gsap.set(buttonTextAfter, { autoAlpha: 0 })
      gsap.set(buttonText, { autoAlpha: 1 })

      const tl = gsap.timeline({ paused: true })

      tl.to(buttonText, {
        yPercent: -100,
        duration: 0.4,
        ease: "power2.inOut",
      })

      button.addEventListener("mouseenter", () => tl.play())
      button.addEventListener("mouseleave", () => tl.reverse())
    })
  },

  curtainHover(elements) {
    gsap.utils.toArray(elements).forEach((element) => {
      let curtainColor =
        element.closest("section")?.getAttribute("data-bg") || element.getAttribute("data-color") || "inherit"
      const curtain = document.createElement("div")
      const trigger = element.parentNode.querySelector(".curtain-hover-trigger")
      curtain.classList.add("curtain-hover-overlay")
      element.parentNode.appendChild(curtain)

      gsap.set(curtain, {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
        y: "100%",
        transformOrigin: "bottom center",
        backgroundColor: curtainColor,
        mixBlendMode: "multiply",
        visibility: "hidden",
        pointerEvents: "none",
      })

      const tl = gsap.timeline({ paused: true })

      const supportsHover = window.matchMedia("(hover: hover)").matches

      if (supportsHover) {
        tl.to(curtain, {
          visibility: "visible",
          y: "0",
          ease: "power2.out",
          duration: 0.4,
        })

        element.addEventListener("mouseenter", () => tl.play())
        element.addEventListener("mouseleave", () => tl.reverse())
      } else {
        tl.to(curtain, {
          visibility: "visible",
          y: "0",
          duration: 0,
        })

        element.addEventListener("touchstart", () => tl.play())
        element.addEventListener("touchend", () => tl.reverse())
      }
    })
  },

  projectCardCurtainHover(elements) {
    gsap.utils.toArray(elements).forEach((element) => {
      const card = element.closest(".project-card")
      if (!card) return

      const link = card.querySelector(".project-card__link")
      const trigger = card.querySelector(".curtain-hover-trigger")
      const figcaption = card?.querySelector("figcaption")
      const figcaptionText = figcaption?.querySelector("span")
      if (!trigger || !figcaption) return

      let curtainColor = element.getAttribute("data-color") || "inherit"
      const curtain = document.createElement("div")
      curtain.classList.add("curtain-hover-overlay")

      trigger.parentNode.insertBefore(curtain, trigger)

      gsap.set(curtain, {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: trigger.offsetHeight,
        width: "100%",
        backgroundColor: curtainColor,
        mixBlendMode: "multiply",
        visibility: "hidden",
        zIndex: 1,
        pointerEvents: "none",
      })

      gsap.set(link, {
        position: "relative",
        overflow: "hidden",
      })

      gsap.set(trigger, { zIndex: 2 })

      gsap.set(figcaptionText, {
        y: "100%",
      })

      const tl = gsap.timeline({ paused: true })

      tl.fromTo(
        curtain,
        { y: "100%", visibility: "hidden" },
        { visibility: "visible", y: "0%", ease: "power2.out", duration: 0.4 },
      ).to(figcaptionText, { y: "0%", opacity: 1, ease: "power2.out", duration: 0.4 }, "-=0.3")

      const playAnimation = () => tl.play()
      const reverseAnimation = () => tl.reverse()

      if (trigger) {
        trigger.addEventListener("mouseenter", playAnimation)
        trigger.addEventListener("mouseleave", reverseAnimation)
      }
      element.addEventListener("mouseenter", playAnimation)
      element.addEventListener("mouseleave", reverseAnimation)
    })
  },
}

// Export individual animation functions
export const fadeUp = gsapAnimations.fadeUp.bind(gsapAnimations)
export const fadeDown = gsapAnimations.fadeDown.bind(gsapAnimations)
export const slideUp = gsapAnimations.slideUp.bind(gsapAnimations)
export const slideDown = gsapAnimations.slideDown.bind(gsapAnimations)
export const createHorizontalScroll = gsapAnimations.createHorizontalScroll.bind(gsapAnimations)
export const buttonRollover = gsapAnimations.buttonRollover.bind(gsapAnimations)
export const curtainHover = gsapAnimations.curtainHover.bind(gsapAnimations)
export const projectCardCurtainHover = gsapAnimations.projectCardCurtainHover.bind(gsapAnimations)
export const wrapElementsWithMask = gsapAnimations.wrapElementsWithMask.bind(gsapAnimations)
